<template>
<div>
   
  <div class="card" v-if="purchases">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcının Satın Alımları</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> adet satış</span>
      </h3>
    </div>
    <!--end::Header-->

    
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
           <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
               <thead>
                   <tr>
                       <th>Ürün</th>
                       <th>Ödeme Tipi</th>
                       <th>Fiyat (€)/Puan</th>
                       <th>Tarih</th>
                       <th>Kazanılan Puan</th>
                       <th>Bitiş Tarihi</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for="(item, i) in purchases" :key="i">
                       <td>{{item.product}}</td>
                       <td>{{paymentTypes[item.paymentTypeId]}}</td>
                       <td>{{item.price}}</td>
                       <td>{{moment(item.purchaseDate).format('DD MMMM YYYY HH:MM')}}</td>
                       <td>{{item.earnedScore}}</td>
                       <td>{{moment(item.expireDate).format('DD MMMM YYYY HH:MM')}}</td>
                         
                   </tr>
               </tbody>
           </table>

           <paging 
            v-if="recordcount>0" 
            :page="page" 
            :pagelength="pagelength" 
            :recordcount="recordcount" 
            @next="next" 
            @prev="prev"
            @gotoPage="gotoPage($event)"
            />

      </div>
    </div>
  </div>
</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels.js"

import moment from 'moment'
import api from '@/core/services/ApiService'
import Paging from '@/components/BD/Paging.vue';
export default {
  components: { Paging },
  name: "Gorusmeler",
  props:['profile'],
  data(){
      return {
          purchases:null,
          recordcount:0,
          paymentTypes:labels.paymentTypes,
          callStatusses:labels.callStatusses,
          pagelength:30,
          page:1,
      }
  },
  watch:{
      page:function(){
        this.getPurchases();
      }
    },

  methods:{
      moment(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      getPurchases(){
         if(this.profile){
            let purchaseType = 0;
            let url ='/User/ListPurchase/'+this.profile.userId+"/"+purchaseType+'/'+this.pagelength+'/'+this.page;

             api.get(url).then((res)=>{
                this.purchases = res.data.listPurchase;
                this.recordcount = res.data.rowCount;
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Satın Almalar", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);
      this.getPurchases();
    
  }
  
}
</script>
